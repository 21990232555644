import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import PrivateRoute from "./privateRoute";
import NotFound from "./components/util/NotFound";

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Pages
const Animation = React.lazy(() => import("./components/pages/Animation"));
const Widgets = React.lazy(() => import("./components/pages/Widgets"));

//Logins
const Defaultlogin = React.lazy(() => import("./components/pages/prebuilt-pages/Defaultlogin"));
const AdminLogin = React.lazy(() => import("./components/pages/prebuilt-pages/AdminLogin"))
const OTP = React.lazy(() => import("./components/pages/prebuilt-pages/OTP"))

const Adminhomepage = React.lazy(() => import("./components/pages/Adminhomepage"));
const PatientHomepage = React.lazy(() => import("./components/pages/patient/Homepage"));
const DoctorHomepage = React.lazy(() => import("./components/pages/doctor/Homepage"));

// patient
const Patientlist = React.lazy(() => import("./components/pages/patient/Patientlist"));
const ExistingConsultancyPatient = React.lazy(() => import("./components/pages/patient/ExistingConsultancyPatient"))

// doctor
const DoctorApprovalList = React.lazy(() => import("./components/pages/doctor/DoctorApprovalList"));
const DoctorApprovalDetails = React.lazy(() => import("./components/pages/doctor/DoctorApprovalDetails"));
const Doctorlist = React.lazy(() => import("./components/pages/doctor/Doctorlist"));

const DoctorProfile = React.lazy(() => import("./components/pages/doctor/DoctorProfile"));
const UpdateDoctorProfile = React.lazy(() => import("./components/pages/doctor/UpdateDoctorProfile"));
const AddScheduleDoctor = React.lazy(() => import("./components/pages/doctor/AddScheduleDoctor"));

//visit summary
const VisitSummary = React.lazy(() => import("./components/pages/appointment/VisitSummary"))

// appointment
const AddAppointmentPatient = React.lazy(() => import("./components/pages/appointment/AddAppointmentPatient"))
const AddFollowupPatient = React.lazy(() => import("./components/pages/appointment/AddFollowupPatient"))

const Appointmentlist = React.lazy(() => import("./components/pages/appointment/Appointmentlist"));

//SymptomChecker
const VitalSignsAndSymptomCheckerPatient = React.lazy(() => import("./components/pages/appointment/VitalSignsAndSymptomCheckerPatient"))
const VitalSignsAndSymptomCheckerDoctor = React.lazy(() => import("./components/pages/appointment/VitalSignsAndSymptomCheckerDoctor"))

//My patients/consultancy tab
const ExistingConsultancy = React.lazy(() => import("./components/pages/my-patients/ExistingConsultancy"))

const NewConsultancy = React.lazy(() => import("./components/pages/my-patients/NewConsultancy"))
const NewConsultancyDoctor = React.lazy(() => import("./components/pages/my-patients/NewConsultancyDoctor"))

const CreatePatient = React.lazy(() => import("./components/pages/my-patients/CreatePatient"))
const ConsultancyHistory = React.lazy(() => import("./components/pages/my-patients/ConsultancyHistoryDoctor"))


// consultancy
const ViewConsultancyDoctor = React.lazy(() => import("./components/pages/consultancy/ViewConsultancyDoctor"))

const AfterConsultancyDetailsAdmin = React.lazy(() => import("./components/pages/consultancy/AfterConsultancyDetailsAdmin"))
const AfterConsultancyDetailsDoctor = React.lazy(() => import("./components/pages/consultancy/AfterConsultancyDetailsDoctor"))
const AfterConsultancyDetailsPatient = React.lazy(() => import("./components/pages/consultancy/AfterConsultancyDetailsPatient"))

const AfterConsultancyPrescribedMedicineUpdateDoctor = React.lazy(() => import("./components/pages/consultancy/AfterConsultancyPrescribedMedicineUpdateDoctor"))

const PatientConsultancyHistoryDoctor = React.lazy(() => import("./components/pages/consultancy/PatientConsultancyHistoryDoctor"))

//payment withdrawl
const InitiatedRequests = React.lazy(() => import("./components/pages/payment-withdrawal/InitiatedRequests"))
const PendingRequests = React.lazy(() => import("./components/pages/payment-withdrawal/PendingRequests"))
const FailedRequests = React.lazy(() => import("./components/pages/payment-withdrawal/FailedRequests"))

// Wallet
const EarningsAndWithdrawalHistory = React.lazy(() => import("./components/pages/earnings-and-withdrawls/EarningAndWithdrawlHistory"))
const WithdrawalRequest = React.lazy(() => import("./components/pages/earnings-and-withdrawls/WithdrawalRequest"))

// secondary profiles
const AddProfile = React.lazy(() =>import("./components/pages/patient-secondary-profile/AddProfile"))
const ProfileList = React.lazy(() =>import("./components/pages/patient-secondary-profile/ProfileList"))

//Patient Health Record
const AddPatientHealthRecord = React.lazy(() => import("./components/pages/patient/AddPatientHealthRecord"))

const ViewTestResultsDoctor = React.lazy(() => import("./components/pages/test-results/ViewTestResultsDoctor"))
const ViewTestResultsPatient = React.lazy(() => import("./components/pages/test-results/ViewTestResultsPatient"))

// apps
const Chat = React.lazy(() => import("./components/pages/apps/Chat"));
const Email = React.lazy(() => import("./components/pages/apps/Email"));
const Todolist = React.lazy(() => import("./components/pages/apps/Todolist"));

// charts
const Chartjs = React.lazy(() => import("./components/pages/charts/Chartjs"));
const Googlecharts = React.lazy(() => import("./components/pages/charts/Googlecharts"));

// dashboard
const Clientmanagement = React.lazy(() => import("./components/pages/dashboard/Clientmanagement"));
const Projectmanagement = React.lazy(() => import("./components/pages/dashboard/Projectmanagement"));
const Socialmedia = React.lazy(() => import("./components/pages/dashboard/Socialmedia"));
const Webanalytics = React.lazy(() => import("./components/pages/dashboard/Webanalytics"));

// department
const Adddepartment = React.lazy(() => import("./components/pages/department/Adddepartment"));
const Departmentlist = React.lazy(() => import("./components/pages/department/Departmentlist"));

// doctor schedule
const Addschedule = React.lazy(() => import("./components/pages/doctor-schedule/Addschedule"));
const Schedulelist = React.lazy(() => import("./components/pages/doctor-schedule/Schedulelist"));

// form
const Formlayouts = React.lazy(() => import("./components/pages/form/Formlayouts"));
const Formvalidation = React.lazy(() => import("./components/pages/form/Formvalidation"));
const Formwizard = React.lazy(() => import("./components/pages/form/Formwizard"));
const Fromelements = React.lazy(() => import("./components/pages/form/Fromelements"));

// human resource

const Addnurse = React.lazy(() => import("./components/pages/human-resource/Addnurse"));
const Addpharmacist = React.lazy(() => import("./components/pages/human-resource/Addpharmacist"));
const Addrepresentative = React.lazy(() => import("./components/pages/human-resource/Addrepresentative"));
const Employeelist = React.lazy(() => import("./components/pages/human-resource/Employeelist"));
const Nurselist = React.lazy(() => import("./components/pages/human-resource/Nurselist"));
const Pharmacistlist = React.lazy(() => import("./components/pages/human-resource/Pharmacistlist"));
const Representativelist = React.lazy(() => import("./components/pages/human-resource/Representativelist"));

// icons
const Flaticons = React.lazy(() => import("./components/pages/icons/Flaticons"));
const Fontawesome = React.lazy(() => import("./components/pages/icons/Fontawesome"));
const Materialize = React.lazy(() => import("./components/pages/icons/Materialize"));

// maps
const Googlemaps = React.lazy(() => import("./components/pages/maps/Googlemaps"));
const Vectormaps = React.lazy(() => import("./components/pages/maps/Vectormaps"));

// notice
const Addnotice = React.lazy(() => import("./components/pages/notice/Addnotice"));
const Noticelist = React.lazy(() => import("./components/pages/notice/Noticelist"));

// popups
const Sweetalerts = React.lazy(() => import("./components/pages/popups/Sweetalerts"));
const Toast = React.lazy(() => import("./components/pages/popups/Toast"));

// prebuilt pages
const Comingsoon = React.lazy(() => import("./components/pages/prebuilt-pages/Comingsoon"));

const UserRegistration = React.lazy(() => import("./components/pages/prebuilt-pages/UserRegistration"));
const Error = React.lazy(() => import("./components/pages/prebuilt-pages/Error"));
const Faq = React.lazy(() => import("./components/pages/prebuilt-pages/Faq"));
const Invoice = React.lazy(() => import("./components/pages/prebuilt-pages/Invoice"));
const Lockscreen = React.lazy(() => import("./components/pages/prebuilt-pages/Lockscreen"));
const Modallogin = React.lazy(() => import("./components/pages/prebuilt-pages/Modallogin"));
const Modalregister = React.lazy(() => import("./components/pages/prebuilt-pages/Modalregister"));
const Portfolio = React.lazy(() => import("./components/pages/prebuilt-pages/Portfolio"));
const Userprofile = React.lazy(() => import("./components/pages/prebuilt-pages/Userprofile"));

// reports
const Doctorreport = React.lazy(() => import("./components/pages/reports/Doctorreport"));
const Patientreport = React.lazy(() => import("./components/pages/reports/Patientreport"));
const Totalreport = React.lazy(() => import("./components/pages/reports/Totalreport"));

// tables
const Basictables = React.lazy(() => import("./components/pages/tables/Basictables"));
const Datatables = React.lazy(() => import("./components/pages/tables/Datatables"));

// uiadvance
const Cropper = React.lazy(() => import("./components/pages/uiadvance/Cropper"));
const Draggable = React.lazy(() => import("./components/pages/uiadvance/Draggable"));
const Modals = React.lazy(() => import("./components/pages/uiadvance/Modals"));
const Rangeslider = React.lazy(() => import("./components/pages/uiadvance/Rangeslider"));
const Sliders = React.lazy(() => import("./components/pages/uiadvance/Sliders"));
const Tour = React.lazy(() => import("./components/pages/uiadvance/Tour"));
const Ratings = React.lazy(() => import("./components/pages/uiadvance/Ratings"));

// uibasic
const Accordions = React.lazy(() => import("./components/pages/uibasic/Accordions"));
const Alerts = React.lazy(() => import("./components/pages/uibasic/Alerts"));
const Badges = React.lazy(() => import("./components/pages/uibasic/Badges"));
const Breadcrumbs = React.lazy(() => import("./components/pages/uibasic/Breadcrumbs"));
const Buttons = React.lazy(() => import("./components/pages/uibasic/Buttons"));
const Cards = React.lazy(() => import("./components/pages/uibasic/Cards"));
const Pagination = React.lazy(() => import("./components/pages/uibasic/Pagination"));
const Preloaders = React.lazy(() => import("./components/pages/uibasic/Preloader"));
const Progressbars = React.lazy(() => import("./components/pages/uibasic/Progressbars"));
const Tabs = React.lazy(() => import("./components/pages/uibasic/Tabs"));
const Typography = React.lazy(() => import("./components/pages/uibasic/Typography"));

const DummySSL = React.lazy(() => import("./components/pages/ssl-dummy/SSLDummy"));
const SSLPaymentSuccess = React.lazy(() => import("./components/pages/ssl-dummy/SSLPaymentSuccess"));
const SpeechToText = React.lazy(() => import("./components/pages/speech-to-text/SpeechToText"));

function App() {
  return (
    <div>
      <div> <Toaster /> </div>
      <Router basename={'/'}>
        <Suspense fallback={<div></div>}>
          <Preloader />
          <Switch>
            {/* <AllProtectedRoutes /> */}

            <Route exact path="/" component={Defaultlogin} />
            <Route exact path="/admin/login" component={AdminLogin} />
            <Route exact path="/otp/verification" component={OTP} />

            <PrivateRoute path="/animation" component={Animation} />
            <PrivateRoute path="/widgets" component={Widgets} />

            <PrivateRoute path={"/admin/homepage"} component={Adminhomepage} />
            <PrivateRoute path={"/doctor/homepage"} component={DoctorHomepage} />
            <PrivateRoute path={"/patient/homepage"} component={PatientHomepage} />

            {/* patient */}            
            <PrivateRoute path="/admin/patient/list" component={Patientlist} />

            {/* doctor */}
            <PrivateRoute path="/admin/doctor/approval/list" component={DoctorApprovalList} />
            <PrivateRoute path="/admin/doctor/approval/details" component={DoctorApprovalDetails} />
            <PrivateRoute path="/admin/doctor/list" component={Doctorlist} />

            <PrivateRoute path="/doctor/profile/view" component={DoctorProfile} />
            <PrivateRoute path="/doctor/profile/update" component={UpdateDoctorProfile} />
            <PrivateRoute path="/doctor/profile/schedule" component={AddScheduleDoctor} />


            {/* My Patients Tab */}
            <PrivateRoute path="/admin/new/consultancy" component={NewConsultancy} />
            <PrivateRoute path="/doctor/new/consultancy" component={NewConsultancyDoctor} />
            
            <PrivateRoute path="/doctor/search/patient" component={ExistingConsultancy} />
            <PrivateRoute path="/patient/existing/consultancy" component={ExistingConsultancyPatient} />
            <PrivateRoute path="/doctor/create/patient" component={CreatePatient} />

            <PrivateRoute path="/doctor/consultancy/history" component={ConsultancyHistory} />
            
            {/* appointment */}
            <PrivateRoute path="/patient/visit/summary" component={VisitSummary} />

            {/* appointment */}
            <PrivateRoute path="/patient/appointment/add" component={AddAppointmentPatient} />
            <PrivateRoute path="/patient/followup/add" component={AddFollowupPatient} />

            <PrivateRoute path="/admin/appointment/list" component={Appointmentlist} />

            {/* SymptomChecker */}
            <PrivateRoute path="/patient/symptom/checker" component={VitalSignsAndSymptomCheckerPatient} />
            <PrivateRoute path="/doctor/symptom/checker" component={VitalSignsAndSymptomCheckerDoctor} />


            {/* consultancy */}
            <PrivateRoute path='/admin/consultancy/details' component={AfterConsultancyDetailsAdmin}/>
            
            <PrivateRoute path="/doctor/consultancy/view" component={ViewConsultancyDoctor} />
            <PrivateRoute path='/doctor/consultancy/details' component={AfterConsultancyDetailsDoctor}/>
            
            <PrivateRoute path='/doctor/consultancy/update/medicine' component={AfterConsultancyPrescribedMedicineUpdateDoctor}/>            
            <PrivateRoute path='/doctor/consultancy/patient/history' component={PatientConsultancyHistoryDoctor}/>

            <PrivateRoute path='/patient/consultancy/details' component={AfterConsultancyDetailsPatient}/>
            

            {/* Patient Sidebar */}
            <PrivateRoute path="/patient/add/profile" component={AddProfile} />
            <PrivateRoute path="/patient/profile/view" component={ProfileList} />

            <Route path="/patient/health/record/add" component={AddPatientHealthRecord} />

            {/* Payment Withdrawl */}
            <PrivateRoute path="/admin/withdrawl/requests/pending" component={PendingRequests} />
            <PrivateRoute path="/admin/withdrawl/requests/initiate" component={InitiatedRequests}/>
            <PrivateRoute path="/admin/withdrawl/requests/fail" component={FailedRequests} />

            {/* Earnings */}
            <PrivateRoute path="/doctor/wallet/history" component={EarningsAndWithdrawalHistory} />
            <PrivateRoute path="/doctor/wallet/withdraw" component={WithdrawalRequest} />


            {/* test results */}
            <PrivateRoute path="/doctor/test/results" component={ViewTestResultsDoctor} />
            <PrivateRoute path="/patient/test/results" component={ViewTestResultsPatient} />


            {/* apps */}
            <PrivateRoute path="/apps/chat" component={Chat} />
            <PrivateRoute path="/apps/email" component={Email} />
            <PrivateRoute path="/apps/to-do-list" component={Todolist} />

            {/* charts */}
            <PrivateRoute path="/charts/chartjs" component={Chartjs} />
            <PrivateRoute path="/charts/google-charts" component={Googlecharts} />

            {/* dashboard */}
            <PrivateRoute path="/dashboard/client-management" component={Clientmanagement} />
            <PrivateRoute path="/dashboard/project-management" component={Projectmanagement} />
            <PrivateRoute path="/dashboard/social-media" component={Socialmedia} />
            <PrivateRoute path="/dashboard/web-analytics" component={Webanalytics} />

            {/* department */}
            <PrivateRoute path="/department/add-department" component={Adddepartment} />
            <PrivateRoute path="/department/department-list" component={Departmentlist} />

            {/* patient */}

            {/* doctor schedule */}
            <PrivateRoute path="/doctor-schedule/add-schedule" component={Addschedule} />
            <PrivateRoute path="/doctor-schedule/schedule-list" component={Schedulelist} />


            <Route path="/user/registration" component={UserRegistration} />
            
            <Route path="/dummy/ssl" component={DummySSL} />
            <Route path="/success" component={SSLPaymentSuccess} />
            <Route path="/voice" component={SpeechToText} />
            {/* form */}
            <PrivateRoute path="/form/form-layout" component={Formlayouts} />
            <PrivateRoute path="/form/form-validation" component={Formvalidation} />
            <PrivateRoute path="/form/form-wizard" component={Formwizard} />
            <PrivateRoute path="/form/form-elements" component={Fromelements} />

            {/* human resource */}
            <PrivateRoute path="/human-resource/add-nurse" component={Addnurse} />
            <PrivateRoute path="/human-resource/add-pharmacist" component={Addpharmacist} />
            <PrivateRoute path="/human-resource/add-representative" component={Addrepresentative} />
            <PrivateRoute path="/staff/list" component={Employeelist} />
            <PrivateRoute path="/human-resource/nurse-list" component={Nurselist} />
            <PrivateRoute path="/human-resource/pharmacist-list" component={Pharmacistlist} />
            <PrivateRoute path="/human-resource/representative-list" component={Representativelist} />

            {/* icons */}
            <PrivateRoute path="/icons/flaticons" component={Flaticons} />
            <PrivateRoute path="/icons/fontawesome" component={Fontawesome} />
            <PrivateRoute path="/icons/materialize" component={Materialize} />

            {/* maps */}
            <PrivateRoute path="/maps/google-maps" component={Googlemaps} />
            <PrivateRoute path="/maps/vector-maps" component={Vectormaps} />

            {/* notice */}
            <PrivateRoute path="/notice/add-notice" component={Addnotice} />
            <PrivateRoute path="/notice/notice-list" component={Noticelist} />
            
            {/* popups */}

            <PrivateRoute path="/popups/sweet-alerts" component={Sweetalerts} />
            <PrivateRoute path="/popups/toast" component={Toast} />

            {/* prebuilt pages */}
            <PrivateRoute path="/prebuilt-pages/coming-soon" component={Comingsoon} />
            <PrivateRoute path="/prebuilt-pages/error" component={Error} />
            <PrivateRoute path="/prebuilt-pages/faq" component={Faq} />
            <PrivateRoute path="/prebuilt-pages/invoice" component={Invoice} />
            <PrivateRoute path="/prebuilt-pages/lock-screen" component={Lockscreen} />
            <PrivateRoute path="/prebuilt-pages/modal-login" component={Modallogin} />
            <Route path="/prebuilt-pages/modal-register" component={Modalregister} />
            <PrivateRoute path="/prebuilt-pages/portfolio" component={Portfolio} />
            <PrivateRoute path="/prebuilt-pages/user-profile" component={Userprofile} />

            {/* reports */}
            <PrivateRoute path="/report/doctor-report" component={Doctorreport} />
            <PrivateRoute path="/report/patient-report" component={Patientreport} />
            <PrivateRoute path="/report/total-report" component={Totalreport} />

            <PrivateRoute path="/admin/prescription/test/report" component={Patientreport} />
            
            {/* tables */}
            <PrivateRoute path="/tables/basic-tables" component={Basictables} />
            <PrivateRoute path="/tables/data-tables" component={Datatables} />

            {/* uiadvance */}
            <PrivateRoute path="/ui-advanced/cropper" component={Cropper} />
            <PrivateRoute path="/ui-advanced/draggables" component={Draggable} />
            <PrivateRoute path="/ui-advanced/modals" component={Modals} />
            <Route path="/ui-advanced/range-slider" component={Rangeslider} />
            <Route path="/ui-advanced/sliders" component={Sliders} />
            <PrivateRoute path="/ui-advanced/tour" component={Tour} />
            <Route path="/ui-advanced/rating" component={Ratings} />
            
            {/* uibasic */}
            <PrivateRoute path="/ui-basic/accordions" component={Accordions} />
            <PrivateRoute path="/ui-basic/alerts" component={Alerts} />
            <PrivateRoute path="/ui-basic/badges" component={Badges} />
            <PrivateRoute path="/ui-basic/breadcrumbs" component={Breadcrumbs} />
            <PrivateRoute path="/ui-basic/buttons" component={Buttons} />
            <Route path="/ui-basic/cards" component={Cards} />
            <PrivateRoute path="/ui-basic/pagination" component={Pagination} />
            <PrivateRoute path="/ui-basic/preloader" component={Preloaders} />
            <Route path="/ui-basic/progress-bars" component={Progressbars} />
            <PrivateRoute path="/ui-basic/tabs" component={Tabs} />
            <PrivateRoute path="/ui-basic/typography" component={Typography} />

            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </div>

  );
}

export default App;
