import toast from "react-hot-toast";
import { MonthList } from "../data/MonthList";

export const GetSentenceCase = (randomString) => {
    return randomString[0].toUpperCase() + randomString.slice(1).toLowerCase();
};

export const GetSentenceCaseWithSplit = (randomString) => {
    let convertedText = "";
    let sentenceCaseText = GetSentenceCase(randomString);
    let sentenceCaseTextSplit = sentenceCaseText.split("_");
    for (let i = 0; i < sentenceCaseTextSplit.length; i++) {
        convertedText = convertedText.concat(sentenceCaseTextSplit[i] + ' ');
    }

    return convertedText;
};


export const GetAge = (randomDate) => {
    const date = new Date().getFullYear();
    let randomDateObject = new Date(randomDate).getFullYear();
    return date - randomDateObject;
};


export const GetSum = (randomObject) => {
    let total = 0;
    for (let i = 0; i < randomObject.length; i++) {
        total = total + randomObject[i];
    }
    return total;
};

export const GetMonth = (randomNumber) => {
    let date = new Date();
    date.setMonth(randomNumber - 1);
    return date.toLocaleString('en-US', {
        month: 'long',
    });
};

export const GetBMI = (feet, inch, weight) => {
    let inMeters = 0;
    inMeters = parseInt(feet) * 0.3048;
    inMeters = inMeters + parseInt(inch) * 0.0254;
    let BMI = (parseFloat(weight) / (inMeters * inMeters)).toFixed(2);
    return BMI;
};

export const isDisabled = (index, arrayLength) => {
    return index < arrayLength;
};

export const YearListDropDown = (hospitalEstablishDate) => {
    let currentYear = new Date().getFullYear();
    let hospitalEstablishYear = new Date(hospitalEstablishDate).getFullYear();
    let yearList = [];

    for (let i = currentYear; i >= hospitalEstablishYear; i--) {
        yearList.push({
            label: i,
            value: i,
            name:'year'
        });
    }

    return yearList;
};

export const CheckUserType = (userObject) => {
    if(userObject.user_type ===''){
        return 'admin'
    }
    else{
        return userObject.user_type
    }
};

export const getDefaultCurrentMonth = (randomNumber) =>{
    for (let i=0; i<MonthList.length; i++){
        if(MonthList[i].value === randomNumber){
            return MonthList[i]
        }
        else{
            continue
        }
    }
}

export const reverseArray = (randomArray) =>{
    let resultArray = []
    for (let i= randomArray.length-1; i>=0; i--){
        resultArray.push(randomArray[i])
    }
    return resultArray
}