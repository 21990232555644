export const MonthList = [
    {
        label:'January',
        value:1,
        name:'month'
    },
    {
        label:'February',
        value:2,
        name:'month'
    },
    {
        label:'March',
        value:3,
        name:'month'
    },
    {
        label:'April',
        value:4,
        name:'month'
    },
    {
        label:'May',
        value:5,
        name:'month'
    },
    {
        label:'June',
        value:6,
        name:'month'
    },
    {
        label:'July',
        value:7,
        name:'month'
    },
    {
        label:'August',
        value:8,
        name:'month'
    },
    {
        label:'September',
        value: 9,
        name:'month'
    },
    {
        label:'October',
        value:10,
        name:'month'
    },
    {
        label:'November',
        value:11,
        name:'month'
    },
    {
        label:'December',
        value:12,
        name:'month'
    },
]