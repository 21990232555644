const appointmentDetailsReducer = ( state = {}, action) => {
    switch(action.type) {

        case "APPOINTMENT_DETAILS":
            return state = action.payload;

        default:
            return state;
    }
};

export default appointmentDetailsReducer; 