export const drugReducer = (state = [], action) => {
    switch (action.type) {
        case "SUGGESTED_DRUGS":
            return action.payload;
        case "CLEAR_DRUG_TEST_DATA":
            return [];
        default:
            return state;
    }
};


export const testReducer = (state = [], action) => {
    switch (action.type) {
        case "SUGGESTED_TESTS":
            return action.payload;
        case "CLEAR_DRUG_TEST_DATA":
            return [];
        default:
            return state;
    }
};